//*==================================================================*
//*             DL COM E IND DE PRODUTOS ELETRONICOS LTDA            *
//*------------------------------------------------------------------*
//*    PROGRAMA....: PROJECT ROUTES                                  *
//*    PROGRAMADOR.: EDER GUIMARAES RODRIGUES  - DL                  *
//*------------------------------------------------------------------*
//*    OBJETIVO....: PROVIDE ROUTES TO WHOLE PROJECT                 *
//*------------------------------------------------------------------*
//*    IMPORTS.....:                                                 *
import { Route, BrowserRouter } from "react-router-dom";
//*                                                                  *
//*------------------------------------------------------------------*
//*    PAGE IMPORTS: ROUTES PROJECT CHECKMI.DL.COM.BR - SELO         *
//*------------------------------------------------------------------*
import DealerInfo from "../../selo/imei/DealerInfo";
import ConsultIMEI from "../../selo/imei/ConsultIMEI";
import ConsultBarcoder from "../../selo/imei/ConsultBarcoder";
import ConsultBarcoder2 from "../../selo/imei/ConsultBarcoderQuagga";

//*                                                                  *
//*==================================================================*
//*                                                                  *
const Routes = () => {
    return (
        <BrowserRouter>
            {/*------------------------------------------------------*
            //*    ROUTES PROJECT CHECKMI.DL.COM.BR - SELO           *
            //*----------------------------------------------------*/}
            <Route component={ConsultIMEI} path="/" exact />
            <Route component={ConsultBarcoder} path="/code/" exact />
            <Route component={DealerInfo} path="/info/" exact />
            <Route component={ConsultBarcoder2} path="/code2/" exact />
        </BrowserRouter>
    )
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default Routes;
//*                                                                  *
//*==================================================================*