import React, { useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";

function filterResults(results) {
  const format = results.map((result) => result.result.format.formatName);
  return format[0];
}

function ResultContainerPlugin(props) {
  const resultsCode = filterResults(props.results);
  const [loading, setLoading] = useState(false);
  const [dbConsult, setDbConsult] = useState({
    SERIAL_NUMBER: "",
    NAME: "",
    message: "",
  });

  async function consultDB(imeiSerial) {
    try {
      setLoading(true);
      const baseURL =
        process.env.REACT_APP_BASE_ENDPOINT_CHECKMI +
        "/product/fromsite/" +
        imeiSerial.results[0].decodedText.replace(/[/]/g, "%2F");
      const response = await axios.get(baseURL, {
        headers: {
          "API-KEY": process.env.REACT_APP_API_TOKEN,
        },
      });
      if (
        response.data !== null &&
        response.data !== undefined &&
        Object.keys(response.data).length > 0
      ) {
        setDbConsult({
          SERIAL_NUMBER: response.data.data.SERIAL_NUMBER || "",
          NAME: response.data.data.NAME || "",
          message: response.data.message,
        });
      }
    } catch (err) {
      switch (err.response.data.statusCode) {
        case 401:
          console.log(" ___________________________ ");
          console.log("|      NÃO AUTORIZADO.      |");
          console.log("|      TENTE NOVAMENTE      |");
          console.log("|---------------------------|");
          console.log("|     STATUS_ERROR: 401     |");
          console.log("|      ConsultIMEI.tsx      |");
          console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
          this.responseMessage("Não autorizado." + "Tente novamente.");
          break;
        case 429:
          console.log(" ___________________________ ");
          console.log("|  EXCESSO DE REQUISIÇÕES,  |");
          console.log("|  AGUARDE PARA CONTINUAR.  |");
          console.log("|---------------------------|");
          console.log("|     STATUS_ERROR: 429     |");
          console.log("|      ConsultIMEI.tsx      |");
          console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
          this.responseMessage(
            "Excesso de requisições," + " aguarde para continuar."
          );
          break;
        case 500:
          console.log(" ___________________________ ");
          console.log("|   SERVIDOR INDISPONÍVEL   |");
          console.log("|     OU EM MANUTENÇÃO.     |");
          console.log("|---------------------------|");
          console.log("|     STATUS_ERROR: 429     |");
          console.log("|      ConsultIMEI.tsx      |");
          console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
          this.responseMessage("Servidor indisponível" + "ou em manutenção.");
          break;
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.results[0] !== undefined) consultDB(props);
  }, [props]);

  return (
    <div>
      <BeatLoader color="#000000" loading={loading} css="" />
      {resultsCode === "EAN_13" || (resultsCode === "EAN_8" && !loading) ? (
        <div className="Barcoder-Result">
          <h1 className="h1-result">Serial: {props.results[0].decodedText}</h1>
          <div class="alert alert-danger" role="alert">
            Código Inválido... Clique para ver as instruções acima (?)
          </div>
        </div>
      ) : dbConsult.SERIAL_NUMBER !== "" && !loading ? (
        <div className="Barcoder-Result">
          <h1 className="h1-result">Serial: {dbConsult.SERIAL_NUMBER}</h1>
          <div class="alert alert-success" role="alert">
            {dbConsult.message}
            <br />
            {dbConsult.NAME}
          </div>
        </div>
      ) : dbConsult.message !== "" && !loading ? (
        <div className="Barcoder-Result">
          <h1 className="h1-result">Serial: {props.results[0].decodedText}</h1>
          <div class="alert alert-danger" role="alert">
            {dbConsult.message}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ResultContainerPlugin;
