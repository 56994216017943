import React, { useState } from "react";
import "./../html5-qrcode/css/style.css";
import "./../html5-qrcode/css/bootstrap.css";
import Html5QrcodePlugin from "../html5-qrcode/Html5QrcodePlugin";
import ResultContainerPlugin from "../html5-qrcode/ResultContainerPlugin";
import image from ".././html5-qrcode/image/icon-ios7-arrow-back-512.jpg";
import instrucao from ".././html5-qrcode/image/Imagem_instrucoes_Serial_IMEI.png";
import dl from "../../assets/image/dl.png";
import mi from "../../assets/image/Xiaomi_logo_(2021-).svg.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Instruções</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Escaneie o IMEI ou Serial </h4>
        <img src={instrucao} className="instrucao-image" alt="Instrucao_Imei_Serial" />
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-fechar" onClick={props.onHide}>Fechar</Button>
      </Modal.Footer>
    </Modal>
  );
}

const ConsultBarcoder = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [decodedResult]);
  };

  return (
    <div className="App">
      <div className="App1">
        <section className="App-section">
          <div className="IM-button-barcoder-goback">
            <a href="https://checkmi.dl.com.br/">
              <button className="IM-barcoder-goback">
                <div className="IM-transaction-barcoder-goback">
                  <p className="IM-svg-goback">❮</p>
                </div>
              </button>
            </a>
          </div>
          <p className="p-instrucao">Cique aqui para ver as instruções →</p>
          <Button className="btn-modal" onClick={() => setModalShow(true)}>
            ?
          </Button>
          { decodedResults.length !== 0 ?
            <div>
            <ResultContainerPlugin results={decodedResults} />
            <Button className="IM-button-new-consult" onClick={() => setDecodedResults([])}>
              Nova consulta
            </Button>
            </div>
            :             <div>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
            </div>
          }
        </section>
      </div>
      <div className="App2">
        <section className="MD-footer">
          <div className="MD-box-logo">
            <a href="https://dl.com.br/" target="_blank" rel="noreferrer">
              <img src={dl} className="MD-brand-logo" alt="DL_Logo" />
            </a>
          </div>
          <div className="MD-box-logo">
            <a href="https://mibrasil.com.br/" target="_blank" rel="noreferrer">
              <img src={mi} className="MD-brand-logo" alt="MI_Logo" />
            </a>
          </div>
          <div className="MD-box-logo"></div>
        </section>
      </div>
    </div>
  );
};
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default ConsultBarcoder;
//*                                                                  *
//*==================================================================*
