import React, { useState } from "react";
import "./../html5-qrcode/css/style.css";
import "./../html5-qrcode/css/bootstrap.css";
import Scanner from "../quagga/Scanner";

const ConsultBarcoder2 = (props) => {
  const { verifyNumber, handleChange } = props;
  const [modalShow, setModalShow] = React.useState(false);
  const [scanCode, setScanCode] = useState('');
  const [modal, setModal] = useState(false);

  const _toggle = () => {
    setModal(!modal);
  };

  const _onDetected = (result) => {
    setModal(false);
    const value = result ? result.codeResult.code : '';
    setScanCode(value);
    verifyNumber(value, 'CODE_128');
  };

  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [decodedResult]);
  };

  return (
    <div>
        <section>
                <div
                  open={modal}
                  onClose={_toggle}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div>
                    <Scanner handleScan={_onDetected} />
                  </div>
                </div>
        </section>
    </div>
  );
};
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default ConsultBarcoder2;
//*                                                                  *
//*==================================================================*
