import React, { useState } from "react";
import "./../html5-qrcode/css/style.css";
import "./../html5-qrcode/css/bootstrap.css";
import Html5QrcodePlugin from "../html5-qrcode/Html5QrcodePlugin";
import ResultContainerPlugin from "../html5-qrcode/ResultContainerPlugin";
import image from ".././html5-qrcode/image/icon-ios7-arrow-back-512.jpg";
import instrucao from ".././html5-qrcode/image/Imagem_instrucoes_Serial_IMEI.png";
import dl from "../../assets/image/dl.png";
import mi from "../../assets/image/Xiaomi_logo_(2021-).svg.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Instruções</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Escaneie o IMEI ou Serial </h4>
        <img src={instrucao} className="instrucao-image" alt="Instrucao_Imei_Serial" />
      </Modal.Body>
    </Modal>
  );
}

const ConsultBarcoderHtml5 = (props) => {
  const { verifyNumber } = props;
  const [modalShow, setModalShow] = React.useState(false);
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
    setDecodedResults((prev) => [decodedResult]);
    const formatCode = decodedResult.result.format.formatName;
    console.log(formatCode);
    verifyNumber(decodedResult.decodedText, formatCode)
  };
  // console.log(decodedResults[0].decodedText);
  return (
    <div>
        <section>
           <div>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(true)}
            />
            <Html5QrcodePlugin
              fps={10}
              // qrbox={100}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
            </div>
        </section>
    </div>
  );
};
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default ConsultBarcoderHtml5;
//*                                                                  *
//*==================================================================*
