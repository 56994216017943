//*==================================================================*
//*             DL COM E IND DE PRODUTOS ELETRONICOS LTDA            *
//*------------------------------------------------------------------*
//*    PROGRAMA....: ConsultIMEI                                     *
//*    PROGRAMADOR.: EDER GUIMARAES RODRIGUES  - DL                  *
//*------------------------------------------------------------------*
//*    OBJETIVO....: VERIFICAR SE O NUMERO DE SERIE DIGITADO COR-    *
//*                  RESPONDE A UM PRODUTO OFICIAL OU NAO OFICIAL    *
//*------------------------------------------------------------------*
//*    IMPORTS.....:                                                 *
import React from "react";
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import ConsultBarcoder2 from "./ConsultBarcoderQuagga";
import ConsultBarcoderHtml5 from "./ConsultBarcoderHtml5";
import Modal from "react-bootstrap/Modal";
import instrucao from ".././html5-qrcode/image/Imagem_instrucoes_Serial_IMEI.png";

//*                                                                  *
//*------------------------------------------------------------------*
//*    CLS IMPORTS.:                                                 *
import Header from '../_models/Header';
//*                                                                  *
//*------------------------------------------------------------------*
//*    IMG IMPORTS.:                                                 *
import prod_top from '../_assets/image/prod_top.png';
import prod_bottom from '../_assets/image/prod_bottom.png';
//*                                                                  *
//*------------------------------------------------------------------*
//*    INTERFACES..:                                                 *
import { Int_IMEIResponse } from '../_assets/utils/Interfaces';
//*                                                                  *
//*------------------------------------------------------------------*
//*    CSS IMPORTS.:                                                 *
import './css/Style.css';
import { Button } from "react-bootstrap";
//*                                                                  *
//*==================================================================*
//*                                                                  *
class ConsultIMEI extends React.Component<{}, {
    handle: String | null | undefined,
    loading: boolean,
    border: boolean,
    value: string,
    selectedRadio: string,
    res: Int_IMEIResponse,
    barcodeView2: boolean,
    barcodeView: boolean,
    showPopup: boolean,
    typeCodebar: string,
    showModal: boolean,
    boxStyle: string,
}> {
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.verifyNumber = this.verifyNumber.bind(this)
        this.state = {
            handle: props.location.state
                ? props.location.state.handle
                : null,
            border: false,
            loading: false,
            value: '',
            selectedRadio: 'S',
            res: {
                data: {
                    NAME: '',
                    PROD_CODE: ''
                },
                message: ""
            },
            barcodeView2: false,
            barcodeView: false,
            showPopup: false,
            typeCodebar: '',
            showModal: false,
            boxStyle: '',
        };
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // GET THE INPUT ENTRY AND UPDATE THE 'value' VARIABLE           *
    //*--------------------------------------------------------------*
    //*                                                              *
    handleChange(e: any) {
        this.setState({
            value: e.target.value,
            typeCodebar: '',
        });
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // GET THE INPUT ENTRY AND UPDATE THE 'selectedRadio' VARIABLE   *
    //*--------------------------------------------------------------*
    //*                                                              *
    onChangeValue(e: any) {
        this.setState({
            selectedRadio: e.target.value
        })
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // SUBMIT FUNCTION TO VERIFY THE IMEI/SERIALNUMBER ENTERED       *
    //*--------------------------------------------------------------*
    //*                                                              *
    handleSubmit(e: any) {
        e.preventDefault();
        let imeiMasked: string = this.state.value;
        const { typeCodebar } = this.state;
        let imeiSerial: string = imeiMasked.replace(/[/;]/g, "%2F");
        if (this.state.value.length > 0) {
            if( typeCodebar === "CODE_128" || typeCodebar === ''){
                if (this.state.selectedRadio === 'I') {
                    var res = this.isIMEIValid(imeiSerial)
                    if (res) {
                        this.dbConsult(imeiSerial)
                    } else {
                        this.responseMessage(
                            "O IMEI digitado está incorreto."
                        )
                        this.setState({
                            boxStyle: "IM-response-box-danger"
                        })
                    }
                } else {
                    this.dbConsult(imeiSerial)
                }
            } else {
                this.responseMessage(
                    "Código de barras incorreto! Leia o código de barras do numero de serie ou IMEI."
                )
                this.setState({
                    boxStyle: "IM-response-box-alert"
                })
            }
        } else {
            this.setState({
                loading: false,
                border: true
            })
        }
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // SUBMIT FUNCTION TO DB CONSULT IMEI/SERIALNUMBER ENTERED       *
    //*--------------------------------------------------------------*
    //*                                                              *
    dbConsult(imeiSerial: String) {
        this.setState({
            border: false,
            loading: true
        })
        let url: string = "";
        if (this.state.handle === null ||
            this.state.handle === undefined ||
            this.state.handle === "") {
            url = process.env.REACT_APP_BASE_ENDPOINT_CHECKMI +
                '/product/fromsite/' + imeiSerial
        } else {
            url = process.env.REACT_APP_BASE_ENDPOINT_CHECKMI +
                '/product/' + this.state.handle + '/' + imeiSerial
        };
        axios.get(url, {
            headers: {
                'API-KEY': process.env.REACT_APP_API_TOKEN
            }
        }).then(response => {
            if (response.data !== null &&
                response.data !== undefined &&
                Object.keys(response.data).length > 0) {
                
                this.setState({
                    loading: false,
                    res: response.data,
                    boxStyle: response.data.message === "Não reconhecido. Sem garantia oficial da DL" ? "IM-response-box-danger" : "IM-response-box-sucess"
                })
            } else {
                this.responseMessage("Erro no retorno da"
                    + "requisição. Tente novamente")
                this.setState({
                    boxStyle: "IM-response-box-danger"
                })
            }
        }).catch(err => {
            switch (err.response.data.statusCode) {
                case 401:
                    console.log(" ___________________________ ");
                    console.log("|      NÃO AUTORIZADO.      |");
                    console.log("|      TENTE NOVAMENTE      |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 401     |");
                    console.log("|      ConsultIMEI.tsx      |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Não autorizado."
                        + "Tente novamente.");
                    break;
                case 429:
                    console.log(" ___________________________ ");
                    console.log("|  EXCESSO DE REQUISIÇÕES,  |");
                    console.log("|  AGUARDE PARA CONTINUAR.  |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 429     |");
                    console.log("|      ConsultIMEI.tsx      |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Excesso de requisições,"
                        + " aguarde para continuar.");
                    break;
                case 500:
                    console.log(" ___________________________ ");
                    console.log("|   SERVIDOR INDISPONÍVEL   |");
                    console.log("|     OU EM MANUTENÇÃO.     |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 429     |");
                    console.log("|      ConsultIMEI.tsx      |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Servidor indisponível"
                        + "ou em manutenção.");
                    break;
            }
            console.log(imeiSerial)
        });
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // SUBMIT FUNCTION TO VALID THE IMEI/SERIALNUMBER ENTERED        *
    //*--------------------------------------------------------------*
    //*                                                              *
    isIMEIValid(e: String) {
        if (e.length !== 15) {
            return false
        } else {
            const eArray = Array.from(String(e), Number);
            var soma = 0;
            for (var i = eArray.length - 1; i >= 0; i--) {
                let numero = eArray[i];
                if (i % 2 === 0) {
                    soma += numero;
                } else {
                    numero = numero * 2;
                    if (numero > 9) {
                        while (numero) {
                            soma += numero % 10;
                            numero = Math.floor(numero / 10);
                        }
                    } else {
                        soma += numero
                    }
                }
            }
            if (soma % 10 === 0) {
                return true
            } else {
                return false
            }
        }
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // RESPONSE MESSAGE                                              *
    //*--------------------------------------------------------------*
    //*                                                              *
    responseMessage(e: String) {
        this.setState({
            loading: false,
            res: {
                data: {
                    NAME: "",
                    PROD_CODE: ""
                },
                message: e
            },
        })
    }
    
    //*                                                              *
    //*--------------------------------------------------------------*
    //* MAIN CLASS RENDER                                            *
    //*--------------------------------------------------------------*
    //*                                                              *
    verifyNumber(value: number, typeCode: string){
        this.setState({
            value: String(value),
            showPopup: false,
            typeCodebar: typeCode,
        })
    }

    render() {
        const { boxStyle, res } = this.state;
        const userAgent = navigator.userAgent;
        const isAndroid = userAgent.includes("Android")

        let border: string = this.state.border
            ? "IM-input-value IM-border"
            : "IM-input-value IM-no-border";
        let loading: JSX.Element = this.state.loading
            ? <BeatLoader color="#000000" loading={true} css="" />
            :
            <section className = { boxStyle }>
                <strong>
                    { res.message }<br />
                    { `${res.data.PROD_CODE ? res.data.PROD_CODE : '' } - ${res.data.NAME}` }
                </strong>
            </section>;
        return (
            <div className="IM-page">
                <section className="IM-body">
                    <img className="IM-top-image"
                        src={prod_top}
                        alt="Top_Products" />
                    <img className="IM-bottom-image"
                        src={prod_bottom}
                        alt="Bottom_Products" />
                    <p className="p-instrucao">Clique aqui para ver as instruções →</p>
                    <Button className="btn-modal barcoder-group" onClick={() => this.setState({showModal: !this.state.showModal})}>
                        ?
                    </Button>
                    <h1 className="IM-title">
                        Consulta <br/>Número de Série!
                    </h1>
                    <p className="IM-medium-text">
                        Digite o IMEI ou Serial.
                    </p>
                    <section className="IM-size-input">

                        <form onSubmit={this.handleSubmit}
                            noValidate={true}>
                            <input className={border}
                                maxLength={
                                    this.state.selectedRadio === 'I'
                                        ? 15 : 20}
                                value={this.state.value}
                                onChange={this.handleChange} />
                            <div className="IM-radio-area"
                                onChange={this.onChangeValue}>
                                <input type="radio"
                                    value="I"
                                    name="imeiserial" />
                                <span className="IM-big-text">
                                    IMEI
                                </span>
                                <input type="radio"
                                    value="S"
                                    name="imeiserial" 
                                    defaultChecked />
                                <span className="IM-big-text">
                                    SERIAL
                                </span>
                            </div>

                            <input className="IM-button-layer"
                                type="submit"
                                value="CONSULTAR" />
                        </form>
                        { isAndroid
                            ? <div>
                                <p className="p-scanner barcoder-group">
                                     Para escanear o código de barras, clique aqui 
                                </p>
                                <div className="IM-button-barcoder barcoder-group">
                                    <button className='IM-barcoder' onClick={() => this.setState({barcodeView: true, barcodeView2: false, showPopup: true, value: '', res: { data: { NAME: '', PROD_CODE: '' }, message: ""}, boxStyle: ''})}>
                                        <div className='IM-transaction-barcoder'>
                                            <svg className="IM-svg" viewBox="0 0 24 24">
                                                <path transform="translate(2, -3)" d="M0 4h4v20h-4zM6 4h2v20h-2zM10 4h2v20h-2zM16 4h2v20h-2zM24 4h2v20h-2zM30 4h2v20h-2zM20 4h1v20h-1zM14 4h1v20h-1zM27 4h1v20h-1zM0 26h2v2h-2zM6 26h2v2h-2zM10 26h2v2h-2zM20 26h2v2h-2zM30 26h2v2h-2zM24 26h4v2h-4zM14 26h4v2h-4z"></path>
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                              </div>
                            : '' }                       
                    </section>
                    {loading}
                    { 
                        !isAndroid
                        ?  <p className="p-message"> *Acessando via smartphone com sistema operacional Android é possível ler o código de barras do IMEI ou Serial através da câmera.</p>
                        : '' 
                    }
                    <Header></Header>
                </section>
                {this.state.showPopup &&
                    <div className='popup'>
                        <div className='popup_inner'>
                            {this.state.barcodeView && <ConsultBarcoderHtml5 className="consult-barcoder" verifyNumber={this.verifyNumber} handleChange={this.handleChange}/>}
                            {this.state.barcodeView2 && <ConsultBarcoder2 className="consult-barcoder" verifyNumber={this.verifyNumber} handleChange={this.handleChange}/>}
                            <button id="btn-barcode" className="IM-button-layer" onClick={() => this.setState({showPopup: false})}>Fechar</button>
                        </div>
                    </div> 
                }

                { this.state.showModal && 
                    <Modal
                        show={this.state.showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">Instruções</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Escaneie o IMEI ou Serial </h4>
                            <img src={instrucao} className="instrucao-image" alt="Instrucao_Imei_Serial" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn-fechar" onClick={() => this.setState({showModal: !this.state.showModal})}>Fechar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }
}
//*                                                                  *
//*==================================================================*
//*                                                                  *
export default ConsultIMEI;
//*                                                                  *
//*==================================================================*