import React, { useEffect } from 'react';
import Quagga from 'quagga';
// import styled from 'styled-components';


const Scanner = (props) => {
  const _onDetected = (result) => {
    let code = result;
    Quagga.stop();
    return props.handleScan(code);
  };

  const _onProcessed = (result) => {
    // let drawingCtx = Quagga.canvas.ctx.overlay,
    //   drawingCanvas = Quagga.canvas.dom.overlay;
    // if (result) {
    //   if (result.boxes) {
    //     drawingCtx.clearRect(
    //       0,
    //       0,
    //       parseInt(drawingCanvas.getAttribute('width'), 10),
    //       parseInt(drawingCanvas.getAttribute('height'), 10)
    //     );
    //     result.boxes
    //       .filter(function (box) {
    //         return box !== result.box;
    //       })
    //       .forEach(function (box) {
    //         Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
    //           color: 'green',
    //           lineWidth: 2,
    //         });
    //       });
    //   }
    //   if (result.box) {
    //     Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
    //       color: '#00F',
    //       lineWidth: 2,
    //     });
    //   }
    //   if (result.box) {
    //     Quagga.ImageDebug.drawPath(
    //       result.line,
    //       { x: 'x', y: 'y' },
    //       drawingCtx,
    //       { color: 'red', lineWidth: 3 }
    //     );
    //   }
    // }
  };

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            //  Ajuste a aparência com css porque afeta a precisão da detecção
            width: 80,
            height: 300,
            // width: 300,
            // height: 300,
            // aspectRatio: { min: 4 / 3, max:  4 / 3 },
          },
          area: {
            // defines rectangle of the detection/localization area
            top: '0%', // top offset
            right: '0%', // right offset
            left: '0%', // left offset
            bottom: '0%', // bottom offset
          },
        },
        frequency: 'full',
        locator: {
          patchSize: 'medium',
          halfSample: false,
        },
        numOfWorkers: 2,
        decoder: {
          readers: [
            // 'code_39_reader',
            // {
            //   format: "ean_reader",
            //   config: {
            //       supplements: [
            //           'ean_5_reader', 'ean_2_reader'
            //       ]
            //   }
            // },
            // 'ean_8_reader',
            'code_128_reader',
            // 'code_39_vin_reader',
            // 'codabar_reader',
            // 'upc_reader',
            // 'upc_e_reader',
            // 'i2of5_reader'
          ],
        },
        // singleChannel: true, // true: only the red color-channel is read
        locate: true,
      },
      function (err) {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
      }
    );
    Quagga.onDetected(_onDetected);
    Quagga.onProcessed(_onProcessed);
    return () => {
      Quagga.stop();
    };
  }, []);

  return (
    <div>
      <div id="interactive" className="viewport" />
    </div>
  );
};

export default Scanner ;
