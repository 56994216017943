//*================================================================*
//*           DL COM E IND DE PRODUTOS ELETRONICOS LTDA            *
//*----------------------------------------------------------------*
//*    PROGRAMA....: DealerInfos                                   *
//*    PROGRAMADOR.: EDER GUIMARAES RODRIGUES  - DL                *
//*----------------------------------------------------------------*
//*    OBJETIVO....: IDENTIFICAR SE UMA REVENDA E AUTORIZADA DL    *
//*----------------------------------------------------------------*
//*    IMPORTS............:                                        *
import React from 'react';
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import { Link } from 'react-router-dom';
//*                                                                *
//*----------------------------------------------------------------*
//*    IMAGE IMPORTS......:                                        *
import dl from '../../assets/image/dl.png';
import mi from '../../assets/image/mi_white.png';
import prod_top from '../_assets/image/prod_top.png';
import prod_bottom from '../_assets/image/prod_bottom.png';
import search from '../_assets/icons/search.png';
//*                                                                *
//*----------------------------------------------------------------*
//*    INTERFACES IMPORTS.:                                        *
import { Int_DealerInfos } from '../_assets/utils/Interfaces';
//*                                                                *
//*----------------------------------------------------------------*
//*    CSS IMPORTS........:                                        *
import './css/Style.css';
//*                                                                *
//*================================================================*
//*                                                                *
class DealerInfos extends React.Component<{}, {
    loadingDealer: boolean,
    loadingAddress: boolean,
    handle: string | null,
    data: Int_DealerInfos
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            loadingDealer: false,
            loadingAddress: false,
            handle: "",
            data: {
                fantasyName: "",
                cnpj: "",
                street: "",
                number: "",
                city: 0,
                cep: "",
                state: 0
            }
        };
    }
    //*                                                            *
    //*------------------------------------------------------------*
    // BUILD THE DEALER INFORMATION AFTER LOAD THE PAGE            *
    //*------------------------------------------------------------*
    //*                                                            *
    componentDidMount() {
        let currentUrl: URL = new URL(window.location.href);
        let urlHandle: string | null = currentUrl.searchParams.get("handle");
        this.setState({
            handle: urlHandle,
            loadingDealer: true,
            loadingAddress: true,
        })
        let url: string = process.env.REACT_APP_BASE_ENDPOINT_CHECKMI
            + '/seller/'
            + urlHandle;
        axios.get(url, {
            headers: {
                'API-KEY': process.env.REACT_APP_API_TOKEN
            }
        }).then(response => {
            if (response.data !== null &&
                response.data !== undefined &&
                Object.keys(response.data).length > 0) {
                this.setState({
                    loadingDealer: false,
                    loadingAddress: false,
                    data: response.data
                })
            } else {
                this.responseMessage("Erro no retorno da"
                    + "requisição. Tente novamente")
            }
        }).catch(err => {
            switch (err.response.data.statusCode) {
                case 401:
                    console.log(" ___________________________ ");
                    console.log("|      NÃO AUTORIZADO.      |");
                    console.log("|      TENTE NOVAMENTE      |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 401     |");
                    console.log("|      DealerInfo.tsx       |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Não autorizado."
                        + "Tente novamente.");
                    break;
                case 429:
                    console.log(" ___________________________ ");
                    console.log("|  EXCESSO DE REQUISIÇÕES,  |");
                    console.log("|  AGUARDE PARA CONTINUAR.  |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 429     |");
                    console.log("|      DealerInfo.tsx       |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Excesso de requisições,"
                        + " aguarde para continuar.");
                    break;
                case 500:
                    console.log(" ___________________________ ");
                    console.log("|   SERVIDOR INDISPONÍVEL   |");
                    console.log("|     OU EM MANUTENÇÃO.     |");
                    console.log("|---------------------------|");
                    console.log("|     STATUS_ERROR: 429     |");
                    console.log("|      DealerInfo.tsx       |");
                    console.log(" ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾ ");
                    this.responseMessage("Servidor indisponível"
                        + "ou em manutenção.");
                    break;
            }



        });
    }
    //*                                                              *
    //*--------------------------------------------------------------*
    // RESPONSE MESSAGE                                              *
    //*--------------------------------------------------------------*
    //*                                                              *
    responseMessage(e: any) {
        this.setState({
            loadingDealer: false,
            loadingAddress: false,
            data: {
                fantasyName: e,
                cnpj: "",
                street: "",
                number: "",
                city: 0,
                cep: "",
                state: 0
            }
        })
    }
    //*                                                            *
    //*------------------------------------------------------------*
    // MAIN CLASS RENDER                                           *
    //*------------------------------------------------------------*
    //*                                                            *
    render() {
        let loadingDealer: String | JSX.Element
            = this.state.loadingDealer
                ? <BeatLoader color="#000000" loading={true} css="" />
                : this.state.data.fantasyName;
        let loadingAddress: JSX.Element = this.state.loadingAddress
            ? <BeatLoader color="#FFFFFF" loading={true} css="" />
            : <strong> {this.state.data.street},
                Número {this.state.data.number}  <br />
                {this.state.data.city} - {this.state.data.state},
                <br />CEP: {this.state.data.cep}
            </strong>;
        return (
            <div className="IM-page-info">
                {/*------------------------------------------------*
                  * SECTION AUTH HEADER                            *
                  *------------------------------------------------*/}
                <section className="IM-header-info">
                    <div className="IM-box-logos">
                        <a href="https://dl.com.br/"
                            target="_blank" rel="noreferrer">
                            <img src={dl}
                                className="IM-logo"
                                alt="DL_Logo" />
                        </a>
                    </div>
                    <div className="IM-box-logos"></div>
                    <div className="IM-box-icons">
                        <Link to={{
                            pathname: "/",
                            state: { handle: this.state.handle }
                        }}>
                            <input className="IM-icon"
                                type="image" src={search}
                                alt="Search_Icon" />
                        </Link>
                    </div>
                </section>
                {/*------------------------------------------------*
                  * SECTION AUTH BODY                              *
                  *------------------------------------------------*/}
                <section className="IM-body-info">
                    <img className="IM-top-image"
                        src={prod_top}
                        alt="Top_Products" />
                    <img className="IM-bottom-image"
                        src={prod_bottom}
                        alt="Bottom_Products" />
                    <a href="https://www.mibrasil.com.br/"
                        target="_blank" rel="noreferrer">
                        <img src={mi}
                            className="IM-logo"
                            alt="MI_Logo" />
                    </a>
                    {/*--------------------------------------------*
                      * SECTION AUTH BODY: DEALER AUTH             *
                      *--------------------------------------------*/}
                    <section className="IM-size-dealer">
                        <div className="IM-oficial-dealer-box">
                            <p className="IM-oficial-dealer-text">
                                Revendedor autorizado: <br />
                            </p>
                            <p className="IM-oficial-dealer-fantasynm">
                                {loadingDealer}
                            </p>
                        </div>
                        <p className="IM-medium-text-info">
                            Se você está no endereço:
                            <br />
                            {loadingAddress}
                        </p>
                    </section>
                    {/*--------------------------------------------*
                      * SECTION AUTH BODY: OFFICIALITY TEXT        *
                      *--------------------------------------------*/}
                    <section>
                        <p className="IM-big-text-info">
                            <strong>PARABÉNS! </strong>
                            VOCÊ ESTÁ EM UM
                            REVENDEDOR AUTORIZADO
                            DL COM GARANTIA OFICIAL PARA
                            PRODUTOS XIAOMI.
                        </p>
                    </section>
                    {/*--------------------------------------------*
                      * SECTION AUTH BODY: CHECK IMEI BUTTON       *
                      *--------------------------------------------*/}
                    <section>
                        <p className="IM-small-text-info">
                            PARA CONSULTAR UM PRODUTO XIAOMI<br />
                            COM GARANTIA OFICIAL DL,
                        </p>
                        <Link to={{
                            pathname: "/",
                            state: { handle: this.state.handle }
                        }}>
                            <button className="IM-button-layer-info">
                                TOQUE AQUI
                            </button>
                        </Link>
                        <p className="IM-small-text-info">
                            PARA INSERIR / LER O
                            NÚMERO DE SÉRIE OU IMEI
                        </p>
                    </section>
                </section>
            </div>
        );
    }
}
//*                                                                *
//*================================================================*
//*                                                                *
export default DealerInfos;
//*                                                                *
//*================================================================*